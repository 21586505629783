import axios from "axios";
// import utils from './KCUtils.js'
// import csvUtils from "./KCCSVUtils.js";
import moment from 'moment-timezone';
const querystring = require('querystring');

var KEY = 'IG)(V{Y^_12317EF6{{a345}^Gga9z';
var REFERID = '2519';
const TIMEZONES = [{"text":"Hawaii Time","value":"Pacific/Honolulu","atpnum":"-6"},{"text":"Alaska Time","value":"America/Anchorage","atpnum":"-4"},{"text":"Pacific Time","value":"America/Los_Angeles","atpnum":"-3"},{"text":"Mountain Time","value":"America/Denver","atpnum":"-2"},{"text":"Central Time","value":"America/Chicago","atpnum":"-1"},{"text":"Eastern Time","value":"America/New_York","atpnum":"0"},{"text":"SE Asia Time","value":"Asia/Bangkok","atpnum":"11"}];
export default {    
    //addThisPassAccountKey,
    isEmpty(obj) {
        return obj === null || obj === undefined || obj === '' || (typeof obj === 'object' && Object.keys(obj).length === 0);
    },
    getCleanAxios(){
        let axiosInstance = axios.create();
        delete axiosInstance.defaults.headers.common['Authorization'];
        return axiosInstance;
    },    
    rgb2hex(rgba) {
        if (rgba.startsWith('#')) {
            return rgba.substring(1);
        }
    
        const match = rgba.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    
        if (!match) {
            return null; // Handle invalid input
        }
    
        const [, r, g, b] = match;
        return `${parseInt(r, 10).toString(16).padStart(2, '0')}${parseInt(g, 10).toString(16).padStart(2, '0')}${parseInt(b, 10).toString(16).padStart(2, '0')}`;
    },
    getAPIParam($passJSON){
        //console.log(passJSON);
        let $logo_image = $passJSON['resource']['logo'];
        let $backFields = $passJSON['resource']['backFields'];
		let $eventTicket = $passJSON['passData']['eventTicket'];
		let $templateData = $passJSON['resource']['templateData'];
		let $cardManagement = {
            name: $passJSON['passData']['logoText'],
            logo_image: $logo_image,
            phone: $backFields['phone'],
            email: $backFields['email'],
            address: $backFields['address'],
            surrounding: $backFields['surrounding'],
            website_name: $backFields['website_name'],
            website: $backFields['website'],
            link1_name: $backFields['link1_name'],
            link1: $backFields['link1'],
            link2_name: $backFields['link2_name'],
            link2: $backFields['link2'],
            header1: $eventTicket['headerFields'][0]['label'],
            header2: $eventTicket['headerFields'][0]['value'],
            more: $backFields['more'],
            html: $backFields['html'].trim(),
            background_blurr: 'yes'
        }
        
        if ($passJSON['resource']['chkBackgroundImage'] || $passJSON['resource']['chkBackgroundVideo']) {
            $cardManagement['style'] = 'image';
            if ($passJSON['resource']['chkBackgroundImage']) {
                $cardManagement['bg_image'] = $passJSON['resource']['backgroundImage'];
                $cardManagement['text'] = this.rgb2hex($passJSON['passData']['foregroundColor']);
                if ($templateData['chkBlurBackgroundImage'] == false) {
                    $cardManagement['background_blurr'] = 'no';
                }
            } else {
                $cardManagement['background_video'] = $passJSON['resource']['backgroundVideoUrl'];
            }
        } else {
            $cardManagement['style'] = 'color';
            $cardManagement['background'] = this.rgb2hex($passJSON['passData']['backgroundColor']);
            $cardManagement['text'] = this.rgb2hex($passJSON['passData']['foregroundColor']);
        }
        if ($passJSON['resource']['chkStripImage']) {
            $cardManagement['banner_image'] = $passJSON['resource']['stripImage'];
        } else {
            $cardManagement['banner_clear'] = 'true';
        }
        // let $thumbnail = $passJSON['resource']['thumbnail'];
        let $cardSettings = {
            welcome_text: $templateData['greetingMessage'],
            suspended_by_default: 'no',
            push_show: 'yes',
            qr_source: 'none',
            qr_format: 'PKBarcodeFormatQR',
        };
        if ($templateData['showWelcomeMessage']) {
            $cardSettings.push_show =  'intro';
            $cardSettings.push_welcome_1 = $eventTicket['primaryFields'][0]['value'];
            $cardSettings.push_welcome_2 = $eventTicket['secondaryFields'][0]['value'];
            $cardSettings.push_welcome_3 = $eventTicket['secondaryFields'][1]['value'];
        }
        // let $cardPush = {
        //     push: $eventTicket['primaryFields'][0]['value'],
        //     message_preferences: '', //leave empty for all
        //     push2: $eventTicket['secondaryFields'][0]['value'],
        //     push3: $eventTicket['secondaryFields'][1]['value'],
        //     thumbnail: $thumbnail
        // };
        let $barcodeData = $templateData['barcodeOther'];
        let $cardBarcode = {'barcode':''};
        if ($barcodeData['enabled']) {
            $cardBarcode = {'barcode': $barcodeData['value']};
            $cardSettings['qr_format'] = $passJSON['passData']['barcode']['format'];
            if (
                (!this.isEmpty($barcodeData.columnName) && !$barcodeData.chkCreateNewColumn) ||
                ($barcodeData.value.includes('{') && $barcodeData.value.includes('}'))
              ) {
                $cardSettings.qr_source = 'other';
              }            
        }
        let cardMain = {
            default_apple_wallet: 'user_preference',
            default_android_wallet: 'user_preference',
            timezone: '0',
            mp_status: 'no'
        };
        if ($templateData.defaultAppleWallet == 'Apple') {
            cardMain.default_apple_wallet = 'apple';
        } else if ($templateData.defaultAppleWallet == 'Mobile') {
            cardMain.default_apple_wallet = 'the_wallet';
        }
        if ($templateData.defaultAndroidWallet == 'Android') {
            cardMain.default_android_wallet = 'google';
        } else if ($templateData.defaultAndroidWallet == 'Mobile') {
            cardMain.default_android_wallet = 'the_wallet';
        }
        const tz = TIMEZONES.filter(item => item['value'] === $templateData.timeZone);
        cardMain.timezone = tz[0].atpnum;
		let ret = {
            cardManagement: $cardManagement,
            // cardPush: $cardPush,
            cardBarcode: $cardBarcode,
            cardSettings: $cardSettings,
            // chkThumbnail: $passJSON['resource']['chkThumbnail'],
            chkStripImage: $passJSON['resource']['chkStripImage'],
            cardMain: cardMain
        };        
        return ret;
        // console.log(ret);
    },
    createPass: async function(acctKey,passKey,passJSON){
        let apiParam = this.getAPIParam(passJSON);
        var ret = {};
        console.log('passJSON: ',passJSON);
        /*			
            $url = "https://addthispass.com/api/card-management/?key=$KEY&referral_id=$REFERID&account_key=$acctKey";
			if (!empty($passKey)) $url .= "&card_id=$passKey";
			$url .= '&' . array2query($passAPIParam['cardManagement']);
			$ret['url-info'] = $url;
			$raw = curlGetJsonWithTimeout($url);
        */
        let url = `https://addthispass.com/api/card-management/?key=${KEY}&referral_id=${REFERID}&account_key=${acctKey}`;
        if(passKey) {
            url += `&card_id=${passKey}`;
        }
        url += "&" + querystring.stringify(apiParam.cardManagement);
        ret.urlInfo = url;
        console.log(url);
        let axios = this.getCleanAxios();
        var cardRet = await axios.get(url);
        console.log(cardRet);
        ret.resultInfo = cardRet;
        if (cardRet.data.ERROR) {
            if(passKey) ret.passKey = passKey;
            ret.success = false;
            ret.message = cardRet.data.ERROR;
            return ret;
        }
        passKey = cardRet.data.CARD;
        ret.passKey = cardRet.data.CARD;

        url = `https://addthispass.com/api/card-main-settings/?key=${KEY}&referral_id=${REFERID}&account_key=${acctKey}&card_id=${passKey}`;
        url += '&' + querystring.stringify(apiParam.cardMain);
        ret.urlMain = url;
        // axios = this.getCleanAxios();
        cardRet = await axios.get(url);
        console.log(cardRet);
        ret.resultMain = cardRet;
        if (cardRet.data.ERROR) {
            ret.success = false;
            ret.message = cardRet.data.ERROR;
            return ret;
        }

        url = `https://addthispass.com/api/card-front-settings/?key=${KEY}&referral_id=${REFERID}&account_key=${acctKey}&card_id=${passKey}`;
        url += '&' + querystring.stringify(apiParam.cardSettings);
        ret.urlSettings = url;
        // axios = this.getCleanAxios();
        cardRet = await axios.get(url);
        console.log(cardRet);
        ret.resultSettings = cardRet;
        if (cardRet.data.ERROR) {
            ret.success = false;
            ret.message = cardRet.data.ERROR;
            return ret;
        }
        
        url = `https://addthispass.com/api/card-back-settings/?key=${KEY}&referral_id=${REFERID}&account_key=${acctKey}&card_id=${passKey}&history=5&sharing=false`;
        ret.urlBackSettings = url;
        // axios = this.getCleanAxios();
        cardRet = await axios.get(url);
        console.log(cardRet);
        ret.resultBackSettings = cardRet;
        if (cardRet.data.ERROR) {
            ret.success = false;
            ret.message = cardRet.data.ERROR;
            return ret;
        }

        if (this.isEmpty(apiParam.cardBarcode['barcode'])) {
            //Clear Barcode
            url = `https://addthispass.com/api/card-barcode/?key=${KEY}&referral_id=${REFERID}&account_key=${acctKey}&card_id=${passKey}&barcode=none&clear=true`;
        } else {
            url = `https://addthispass.com/api/card-barcode/?key=${KEY}&referral_id=${REFERID}&account_key=${acctKey}&card_id=${passKey}`;
            url += '&' + querystring.stringify(apiParam.cardBarcode);
        }
        ret.urlBarcode = url;
        // axios = this.getCleanAxios();
        cardRet = await axios.get(url);
        console.log(cardRet);
        ret.resultBarcode = cardRet;
        if (cardRet.data.ERROR) {
            ret.success = false;
            ret.message = cardRet.data.ERROR;
            return ret;
        }

        // if (apiParam.chkThumbnail == true) {
        //     url = `https://addthispass.com/api/card-push/?key=${KEY}&referral_id=${REFERID}&account_key=${acctKey}&card_id=${passKey}`;
        //     url += '&' + querystring.stringify(apiParam.cardPush)+"&holders=";//&push_date=2099-01-01&push_time=01:00&push_timezone=0
        //     ret.urlPush = url;
        //     // axios = this.getCleanAxios();
        //     cardRet = await axios.get(url);
        //     console.log(cardRet);
        //     ret.resultPush = cardRet;
        // }
        ret.success = true;
        console.log(ret);
        return ret;
    },

    pushMessage: async function(pushMessage, contacts) {
        console.log(pushMessage);
        let acctKey = pushMessage.acctKey;
        let passKey = pushMessage.passKey;
        let push = pushMessage.line1Text;
        let push2 = pushMessage.line2Text;
        let push3 = pushMessage.line3Text;
        let thumbnail = pushMessage.thumbnail;
        let scheduleDT = moment.tz(pushMessage['sendDate']+' '+pushMessage['sendTime'],'YYYY-MM-DD hh:mmA',pushMessage.timezone);
        let sendDate = scheduleDT.tz('America/New_York').format('YYYY-MM-DD');
		let sendTime = scheduleDT.tz('America/New_York').format('HH:mm');
        const tz = TIMEZONES.filter(item => item['value'] === pushMessage.timezone);
        let timezone = tz[0].atpnum;
        let address = pushMessage['address']; 
        let latitude = pushMessage['latitude'];  
        let longitude = pushMessage['longitude']; 
        let operator = pushMessage['operator']; 
        let range = pushMessage['range']; 
        let target = pushMessage['target'];
        // let addressing = pushMessage['addressing'];
        let preference = pushMessage['preference'];
        let initURL = `https://addthispass.com/api/card-push/?key=${KEY}&referral_id=${REFERID}&account_key=${acctKey}&card_id=${passKey}&thumbnail=${encodeURIComponent(thumbnail)}&push_date=${sendDate}&push_time=${sendTime}&push_timezone=${timezone}&message_preferences=${preference}`;
        let ret = {success: true, pushMessage: []};
        // holders is required
        if (target == 'visitor') {
            let url = `https://addthispass.com/api/card-engagement/?key=${KEY}&referral_id=${REFERID}&account_key=${acctKey}&card_id=${passKey}&filter_by=signup`;
            console.log(url);
            let engagementData = await this.getEngagementData(url);
            console.log('engagementData.length', engagementData.length);
            if (engagementData.length == 0) return "This pass no tag";
            let activeTags = this.getActiveSignUpTags(engagementData);
            console.log('activeTags.length', activeTags.length);
            if (activeTags.length == 0) return "This pass no active tag";
            let axios = this.getCleanAxios();
            for (let i=0; i<contacts.length; i++) {
                let contactData = contacts[i];
                // if (i==0) {
                //   contactData._id = '6565b010bce5e8a02029851f';
                //   contactData.purl = 'letawood2';
                // } else if (i==1) {
                //   contactData._id = '6565b010bce5e8a020298511';
                //   contactData.purl = 'toddfogelberg2';
                // } else 
                // if (i==2) {
                //   contactData._id = '65657f10bce5e8a020296f6165c08e4ab7947a4267a82d0165bb165930f3cf8323386bf9';
                //   contactData.purl = 'tompederson';
                // } else if (i==3) {
                //   contactData._id = '65657f10bce5e8a020296f5165c08e4ab7947a4267a82d0165bb165930f3cf8323386bf9';
                //   contactData.purl = 'feriajackson';
                // }
                console.log('contactData: ', contactData);
                if (this.isEmpty(contactData._id) || this.isEmpty(contactData.purl)) continue;
                if (!this.hasStringInArray(contactData._id+contactData.purl, activeTags)) continue;
                let pushRendered = this.renderBracesWithArray(push, contactData);
                let push2Rendered = this.renderBracesWithArray(push2, contactData);
                let push3Rendered = this.renderBracesWithArray(push3, contactData);
                let completedURL = initURL+`&push=${encodeURIComponent(pushRendered)}&push2=${encodeURIComponent(push2Rendered)}&push3=${encodeURIComponent(push3Rendered)}&holders=${contactData._id}${contactData.purl}`;
                console.log('urlPush: ', completedURL);
                let cardRet = await axios.get(completedURL);
                console.log("result: ", cardRet.data);
                ret.pushMessage.push({url: completedURL, result: cardRet.data});
            }
        } else {
            let completedURL = initURL+`&push=${encodeURIComponent(push)}&push2=${encodeURIComponent(push2)}&push3=${encodeURIComponent(push3)}&holders=`;
            if (target == 'geofencing' && !((this.isEmpty(address) && (this.isEmpty(latitude) || this.isEmpty(longitude))) || this.isEmpty(operator) || this.isEmpty(range))) {
                if (!(this.isEmpty(latitude) || this.isEmpty(longitude))) { //addressing == 'latitude'
                    completedURL += `&distance_lat=${latitude}&distance_lon=${longitude}`;
                } else {
                    completedURL += `&distance_address=${encodeURIComponent(address)}`;
                }
                completedURL += `&distance_op=${operator}&distance_num=${range}`;
            } else if (target == 'registered') {
                completedURL += '&user_type=registered';
            } else if (target == 'nonregistered') {
                completedURL += '&user_type=nonregistered';
            }
            console.log('urlPush: ', completedURL);
            let axios = this.getCleanAxios();
            let cardRet = await axios.get(completedURL);
            console.log("result", cardRet.data);
            ret.pushMessage.push({url: completedURL, result: cardRet.data});
        }
        return ret;
    },
    getEngagementData: async function(url){
        try{
            let axios = this.getCleanAxios();
            let ret  = await axios.get(url);
            console.log("getEngagementData: ",ret.data);
            return ret.data == null ? [] : ret.data;
        }catch(ex){
            console.error("getEngagementData: ",ex.message);
            if(ex.response.status == 404){
                // not found 
                return [];
            }
            return [];
        }
    },
    getActiveSignUpTags: function(data) {
        // Filter the array to get only the objects where Device Status is "Updates Enabled"
        const enabledDevices = data.filter(item => item['Device Status:'] === 'Updates Enabled');
      
        // Extract the Sign Up Tag values from the filtered objects
        const signUpTags = enabledDevices.map(item => item['Sign Up Tag Custom Unique ID:']);
      
        return signUpTags;
    },
    hasStringInArray: function(stringToCheck, arrayToSearch) {
        // Loop through the array
        for (let i = 0; i < arrayToSearch.length; i++) {
            // Check if the current element contains the stringToCheck
            if (arrayToSearch[i].includes(stringToCheck)) {
                // If found, return true
                return true;
            }
        }
        // If not found after checking all elements, return false
        return false;
    },
    renderBracesWithArray: function(template, data) {
        var regex = /{(.*?)}/g;
        var matches = template.match(regex);
      
        if (matches) {
            for (var i = 0; i < matches.length; i++) {
                var match = matches[i];
                var key = match.slice(1, -1); // Extract the key between ##
                template = template.replace(match, data[key.toLowerCase()]);
            }
        }
      
        return template;
    },
    postCSVFile: async function(endpoint, acctKey,passKey,formData) {
        let axios = this.getCleanAxios();
        let ret = await axios.post(`https://addthispass.com/api/${endpoint}/?key=${KEY}&referral_id=${REFERID}&account_key=${acctKey}&card_id=${passKey}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        return ret;
    },
    getLocationData: async function(acctKey,passKey,dataType) {
        let url = `https://addthispass.com/api/card-auto-messaging-details/?key=${KEY}&referral_id=${REFERID}&account_key=${acctKey}&card_id=${passKey}&type=${dataType}`;
        console.log('url: '+url);
        let axios = this.getCleanAxios();
        let ret = await axios.get(url);
        return ret;
    },
    setMainSettings: async function(acctKey,passKey,apiParam) {
        let url = `https://addthispass.com/api/card-main-settings/?key=${KEY}&referral_id=${REFERID}&account_key=${acctKey}&card_id=${passKey}`;
        url += '&' + querystring.stringify(apiParam);
        console.log('url: '+url);
        let axios = this.getCleanAxios();
        let ret = await axios.get(url);
        return ret;
    },    

    alterPreference: async function(acctKey,passKey,action,preferenceData) {
        console.log('savePreference: ', acctKey, passKey, preferenceData);
        let id = preferenceData.id;
        let preference = preferenceData.preference;
        let url = `https://addthispass.com/api/card-preferences/?key=${KEY}&referral_id=${REFERID}&account_key=${acctKey}&card_id=${passKey}&action=${action}&name=${preference}&id=${id}`;
        console.log(url);

        let axios = this.getCleanAxios();
        let cardRet = await axios.get(url);
        console.log("result", cardRet.data);
        return cardRet.data;
    }
}   